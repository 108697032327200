import _ from 'lodash';
import dayjs from 'dayjs';

require('./../libs/pinyinUtil');

export const pinyin = (str, mode) => {
  if (!str) {
    return '';
  }
  const { pinyinUtil } = window;
  let pys = pinyinUtil.getPinyin(str, ' ', false).split(' ');
  pys = _.filter(pys, i => i !== null && i !== '');
  const firstChars = pys.map(i => i[0]);
  switch (mode) {
    case 1:
      break;
    case 2:
      pys = firstChars.join('');
      break;
    default:
      if (!_.isEqual(pys, firstChars)) {
        pys = _.concat(firstChars, ['|'], pys);
      }
      break;
  }
  return pys.join('');
};
export const jpGenerate = (formData, val, field = 'nameJp') => {
  formData[field] = val ? pinyin(val) : val;
};
/**
 *获取树形数据
 * @param data
 * @param sid 当前已选择数据id
 * @param pid //父级数据id
 * @param depth //tree默认levelKey
 * @returns {Array}
 */
export function getTree(data = [], merge = false, sid, pid = null) {
  const children = [];
  let depth; // el-tree-table  需要用此字段表示层级
  _.forEach(data, value => {
    const node = value;
    if (((!pid && !node.pid) || node.pid === pid) && node.id !== sid) {
      if (node.pid) {
        // 有父级，子级的depth ++
        let { depth: dt } = _.find(data, { id: node.pid });
        depth = dt ? ++dt : 1;
      } else {
        depth = 1;
      }
      node.depth = depth; // 原数据加上depth树形
      // 判断node中是否有children
      const { children: isChildren = '', ...nodes } = node;
      const childrens = getTree(data, merge, sid, node.id);
      if (isChildren) {
        children.push({
          key: node.id,
          value: merge ? `${node.id},${node.name}` : `${node.id}`,
          label: node.name,
          depth,
          ...nodes,
          children: childrens !== undefined ? childrens : [],
        });
      } else {
        children.push({
          key: node.id,
          value: merge ? `${node.id},${node.name}` : `${node.id}`,
          label: node.name,
          depth,
          ...nodes,
          children: childrens,
        });
      }
    }
  });
  return children.length ? children : undefined;
}
/**
 *
 * @param {Array} data
 * @param {String} sid
 * @param {String} pid
 */
export function makeMenu1(data = [], sid, pid = null) {
  const subs = [];
  _.forEach(data, value => {
    const node = value;
    if (((!pid && !node.pid) || node.pid === pid) && node.id !== sid) {
      subs.push({
        key: node.id,
        index: node.path || node.id, // path不存在。如果index相同会导致菜单无法只展开一个
        title: node.name,
        icon: node.icon,
        subs: makeMenu1(data, sid, node.id),
      });
    }
  });
  return subs.length ? subs : undefined;
}
export function makeMenu(data = [], sid, pid = null) {
  const subs = [];
  _.forEach(data, value => {
    const node = value;
    if (((!pid && !node.pid) || node.pid === pid) && node.id !== sid) {
      subs.push({
        key: node.id,
        index: node.path || node.id, // path不存在。如果index相同会导致菜单无法只展开一个
        title: node.name,
        icon: node.icon,
        subs: makeMenu(data, sid, node.id) || node.subs,
      });
    }
  });
  return subs.length ? subs : undefined;
}
/**
 * 获取所有父id
 * @param data
 * @param id
 * @returns {Array}
 */
export function getTreeParentIds(data = [], id) {
  let node;
  let cid = id;
  const pids = [];
  const fn = i => i.id === cid;
  do {
    node = _.find(data, fn);
    if (node && node.pid) {
      cid = node.pid;
      pids.push(cid);
    }
  } while (node && node.pid);
  return pids;
}

/**
 * 获取所有子节点
 * @param data
 * @param pid
 * @returns {*}
 */
export function getTreeAllChildrenIds(data = [], pid = null) {
  const children = [];
  _.forEach(data, value => {
    const node = value;
    if ((!pid && !node.pid) || node.pid === pid) {
      children.push(node.id);
      const subs = getTreeAllChildrenIds(data, node.id);
      if (subs) {
        children.push(...subs);
      }
    }
  });
  // for (const i in data) {
  //   const node = data[i];
  //   if ((!pid && !node.pid) || node.pid === pid) {
  //     children.push(node.id);
  //     const subs = getTreeAllChildrenIds(data, node.id);
  //     if (subs) {
  //       children.push(...subs);
  //     }
  //   }
  // }
  return children.length ? children : undefined;
}

/**
 * n选三  组合算法
 * @param {Array} subjects 选课列表
 */
export function combinations(subjects) {
  // if (list is not list) return messsage.error;
  const { length } = subjects;
  if (length < 3) return [];
  const firstLoopTimes = length - 2; // 第一个不可能是后两个
  const secendLoopTimes = length - 1; // 第二个不可能是最后一个 或者 第一个
  // if (length < num) return messsage.error;
  const res = [];
  for (let l = 0; l < firstLoopTimes; l++) {
    for (let m = l + 1; m < secendLoopTimes; m++) {
      for (let n = m + 1; n < length; n++) {
        res.push([subjects[l], subjects[m], subjects[n]]);
      }
    }
  }
  return res;
}

/**
 * n选二  组合算法
 * @param {Array} subjects 选课列表
 */
export function combinationsForTwo(subjects) {
  // if (list is not list) return messsage.error;
  const { length } = subjects;
  if (length < 2) return [];
  const firstLoopTimes = length - 1; // 第一个不可能是后两个
  // if (length < num) return messsage.error;
  const res = [];
  for (let l = 0; l < firstLoopTimes; l++) {
    for (let m = l + 1; m < length; m++) {
      res.push([subjects[l], subjects[m]]);
    }
  }
  return res;
}
/**
 * 日期转换
 * @param {*} date 需要转化的日期
 * @param {*} format 需要转化为的格式
 */
export const dateFormat = (date, format = 'YYYY-MM-DD HH:mm') => dayjs(date).format(format);
